<template>
	<div class="partial partial--privacy privacy">
		<h2>Privacy Policy</h2>

<p>One of the core features of Quick Patient is privacy. We have designed an application for storing your most personal information in the most secure manner available. At no time do we store your information, ask for registration, or obtain any uniquely identifying information that we can connect to your profile.</p>

<p>Quick Patient provides a simple service to facilitate healthcare provider visits. Our Privacy Policy helps explain our information practices.</p>

<p>When we say “Quick Patient,” “our,” “we,” “application,” or “us,” we’re talking about Quick Patient LLC. This Privacy Policy (“Privacy Policy”) applies to all of our apps, services, features, software, and website (together, “Services”) unless specified otherwise.</p>

<p>Last Updated on August 26, 2019.</p>

<h4>Information We Collect and How Is It Used?</h4>

<h5>User Provided Information</h5>

<p>The Application obtains the information you provide when you download the Application per the rules and regulations of the respective mobile app stores. The usefulness of the Application is not dependent on any type of registration with our services. </p>

<ul>
	<li><strong>Account Information</strong>. There is no account to be created or registration to take place to use our services.</li>

	<li><strong>Your Profile Information</strong>. We do not retain your profile in the ordinary course of providing our Services to you. Once your profile information are delivered, they are deleted from our servers, or are automatically removed after a very short-specified amount of time (seconds). A profile can not be transferred without confirmation that the recipient is there and active to receive it. If, for some reason, a profile transfer is not received within 10 seconds, all of the transfer information is deleted from our servers. We leverage End-to-end encryption, which means that your profile information is encrypted to protect against us and any other third parties from being able to read it.</li>

	<li><strong>Customer Support</strong>. We will never ask for personally identifiable information when requesting support. This may limit us to the level of support that we can provide, but we do not have access to any of your profile information at any point in time.</li>
</ul>

<h5>Automatically Collected Information</h5>

<ul>
	<li><strong>Usage and Log Information</strong>. We collect only service-related, diagnostic and performance information. This includes information about Application such as diagnostic, crash and performance logs. This does not contain any personally identifiable information.</li>

	<li><strong>Device and Connection Information</strong>. We collect only non-personally identifiable device information. This may include device model, operating system information, mobile network information and device identifiers. This is only for analytical purposes and can never be merged with personally identifiable data, as we do not retain that data.</li>
</ul>

<h5>Third-Party Information</h5>

<p>Only aggregated, anonymized data is periodically transmitted to external services to help us improve the Application and our service. We will share your information with third parties only in the ways that are described in this privacy statement.</p>

<p>We may disclose User Provided and Automatically Collected Information:</p>

<ul>
	<li>as required by law, such as to comply with a subpoena, or similar legal process;</li>
	<li>when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</li>
	<li>with our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
	<li>if Quick Patient LLC is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of this information, as well as any choices you may have regarding this information.</li>
</ul>

<h5>Healthcare Providers, Doctors and Practices</h5>

<p>Your healthcare provider, doctor or practice should have a separate Privacy Policy not provided by Quick Patient LLC. Please check with your provider to find relevant privacy information outside of our purview. This Privacy Policy does not extend to the management of your data once collected by the provider nor does theirs apply to us. </p>

<h5>Data Retention Policy</h5>

<p>We do not retain any of your profile information, for any reason. Your information is stored on our servers in only a transactional-sense, and is fully-encrypted to prevent us or any third parties from viewing.</p>

<h5>Security</h5>

<p>We are concerned about safeguarding the confidentiality of your information. We provide physical, electronic, and procedural safeguards to protect information we process and maintain. For example, we limit access to this information to authorized employees and contractors who need to know that information in order to operate, develop or improve our Application. Please be aware that, although we endeavor provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches.</p>

<h5>Changes</h5>

<p>This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here and informing you via email or text message. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes. You can check the history of this policy by clicking here.</p>

<h5>Your Consent</h5>

<p>By using the Application, you are consenting to our processing of your information as set forth in this Privacy Policy now and as amended by us. "Processing,” means using cookies on a computer/hand held device or using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information, all of which activities will take place in the United States. If you reside outside the United States your information will be transferred, processed and stored there under United States privacy standards.</p>

<h5>Contact us</h5>

<p>If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email at privacy@getquickpatient.com.</p>

	</div>
</template>

<script>
export default {
	name: 'privacy',
	metaInfo: () => ({
		title: 'Privacy Policy',
		titleTemplate: '%s | Quick Patient. Instant Patient Check-in.',
	}),
}
</script>
